import { useState } from "react";
import { useBranch, useTranslation } from "@circle/gestalt-app";
import PropTypes from "prop-types";
import { DropDownSelect } from "@circle/kip-components";
import { FormActions } from "./FormActions";
import md5 from "md5";
import { LicensePlantWidget } from "./generic/licensePlantWidget/LicensePlantWidget";
import { fromFakeUTC, toFakeUTC } from "../helper/helper";

const LicenseForm = ({ customer = {}, licenses = [], ...props }) => {
    const { translate }         = useTranslation();
    const { customers, plants } = useBranch({
        customers: ["customers"],
        plants:    ["failsavePlants"]
    });
    const [hash]                   = useState(md5(JSON.stringify({ customer: customer, licenses: licenses })));
    const [_customer, setCustomer] = useState(customer);
    const [_licenses, setLicenses] = useState(licenses);

    const isAllValid = _licenses.every(item => item.value !== "" && (toFakeUTC(item.expiryDate) >= new Date()) || item.expiryDate === null);

    const isValid = _customer && isAllValid;

    const customerPlants = plants?.filter(x => x.tenant === _customer.id);
    const options = customers?.map(x => ({
        value:   x.id,
        label:   x.name,
        checked: _customer?.id === x.id
    }));

    const apps = [
        {
            name:  "OEEpulse",
            appId: "OEEpulse"
        },
        {
            name:  "routines.manager.header",
            appId: "routines-manager"
        }
    ];

    const appOptions = plantId => apps.map(x => ({
        appId:      x.appId,
        label:      translate(x.name),
        plant:      _licenses.filter(y => y.plantId === plantId),
        plantId:    plantId,
        expiryDate: _licenses.filter(y => y.plantId === plantId).find(y => y.appId === x.appId || x.appId === "OEEpulse" && (y.appId === "performance-monitor" || y.appId === "message-monitor"))?.expiryDate || null
    }));

    const onSubmit = e => {
        if(!isValid) return null;

        return props.onSubmit({
            id:         props.id,
            customerId: _customer.id,
            licenses:   _licenses.map(x => ({
                plantId:    x.plantId,
                appId:      x.appId,
                expiryDate: x.expiryDate ? fromFakeUTC(x.expiryDate) : null
            }))
        }, e);
    };

    const onCustomerSelect = opts => {
        const selected    = opts.find(x => x.checked);
        const data        = customers.find(x => x.id === selected?.value);
        const currentHash = md5(JSON.stringify({ customer: data, licenses: _licenses }));

        props.onChange("customer", data, hash !== currentHash);
        setCustomer(data);
    };

    const onChangeLicenses = (plantId, values) => {
        const finalLicenses = values.map(license => ({
            ...license,
            pantId: plantId
        }))
        .filter(obj => obj.expiryDate !== null)
        .reduce((acc, obj) => {
            if(obj.appId !== "OEEpulse") {
                acc.push(obj);
                return acc;
            }

            const prodMonitor    = { ...obj, appId: "performance-monitor" };
            const messageMonitor = { ...obj, appId: "message-monitor" };

            acc.push(prodMonitor, messageMonitor);
            return acc;
        }, []);

        const finalPlantLicenses = _licenses
            .filter(x => x.plantId !== plantId)
            .concat(finalLicenses);

        const currentHash = md5(JSON.stringify({ customer: _customer, licenses: finalPlantLicenses }));

        props.onChange("licenses", finalPlantLicenses, hash !== currentHash);
        setLicenses(finalPlantLicenses);
    };

    return (
        <>

            <div className="customer-form form">
                <span className="header font-bold">{ translate("sidebar.headline").toUpperCase() }</span>
                <div className="flex form-row mt-10 mb-5">
                    <div className="flex-column flex-grow">
                        <span className="form-header font-bold">
                            { translate("customer.ele.header") } *
                        </span>
                        <DropDownSelect
                            className="full-width"
                            isCustomInputEnabled={false}
                            closeOnOptionClick
                            onChange={onCustomerSelect}
                            options={options}
                            placeholder={translate("choose.elements")}
                        />
                    </div>
                </div>

                <div className="mt-5">
                    <span className="form-header font-bold">
                        { translate("plants.ele.header") }
                    </span>
                    {customerPlants.map((x, key) =>
                        <div key={key} className="flex form-row mt-1">
                            <div className="flex-column flex-grow">
                                <LicensePlantWidget
                                    plant={x}
                                    name={translate(x.name)}
                                    onChangeLicenses={val => onChangeLicenses(x.id, val)}
                                    licenses={appOptions(x.id)}
                                />
                            </div>
                        </div>
                    )}
                </div>

            </div>
            <FormActions
                isValid={isValid}
                onCancel={props.onCancel}
                onSubmit={onSubmit}
            />
        </>
    );
};

LicenseForm.propTypes = {
    customer: PropTypes.object,
    licenses: PropTypes.arrayOf(PropTypes.object),
    id:       PropTypes.string,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func
};

export { LicenseForm };
